<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">Loanbot Verifications</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :url="verifications.url"
        :columns="verifications.columns"
        :loading="verifications.loading"
        :query="verifications.query"
        :onClick="click"
        ref="table"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn etc."
              class="mb-5"
              v-model="verifications.query"
              @submit="reloadTable"
            />
            <div class="flex items-start flex-wrap xl:flex-no-wrap">
              <div class="flex flex-wrap items-center w-full">
                <span class="processor-filter-title">Filters:</span>
                <template v-for="(filter, index) in verifications.filters">
                  <span
                    v-html="filter.title"
                    :key="index"
                    class="processor-filter-item"
                    :class="{ active: currentFilter === filter }"
                    @click.prevent="toggleFilter(filter)"
                  />
                </template>
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="selectedUser"
      :loanbot-verification="verifications.selected"
      ref="userModal"
      @delete="userDeleted"
      @update="reloadTable"
      @close="userModalClosed"
    />

  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'AdminLoanbotVerifications',
  data() {
    return {
      currentFilter: null,
      verifications: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/loanbot/verifications`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'User',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'remark',
            th: 'Remark',
          },
          {
            name: 'status',
            th: 'Status',
            render: ({ status }) => {
              let color;
              switch (status) {
                case 'approved':
                  color = 'green';
                  break;
                case 'declined':
                  color = 'red';
                  break;
                case 'failed':
                  color = 'orange';
                  break;
                case 'undecided':
                default:
                  color = 'gray';
                  break;
              }

              return `<div class="badge badge-${color}-soft-outline">${status}</div>`
            }
          },
          {
            name: 'started_at',
            th: 'Date Attempted',
            render: ({ started_at }) => {
              return moment(started_at).fromNow();
            }
          },
          {
            name: 'updated_at',
            th: 'Date Completed',
            render: ({ status, updated_at }) => {
              return status === 'failed' ? '----' : moment(updated_at).fromNow();
            }
          },
          {
            name: 'time_taken',
            th: 'Time Taken',
            render: ({ status, started_at, updated_at }) => {
              return status === 'failed' ? '----' : moment(started_at).from(moment(updated_at), 'seconds');
            }
          }
        ],
        filters: [
          { name: 'all', title: 'All' },
          { name: 'undecided', title: 'Undecided' },
          { name: 'failed', title: 'Failed' },
          { name: 'declined', title: 'Declined' },
        ],
      }),
    }
  },
  computed: {
    queryFilter() {
      const queryFilter = this.$route.query.filter;
      if (!queryFilter) {
        return null;
      }

      return this.verifications.filters.find(filter => filter.name === queryFilter);
    },
    selectedUser () {
      return this.verifications.selected?.user;
    },
  },
  mounted() {
    if (this.queryFilter) {
      return this.toggleFilter(this.queryFilter);
    }
    this.toggleFilter(this.verifications.filters[1]);
  },
  methods: {
    click(user) {
      this.verifications.selected = user;
      this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData()
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }
      this.$refs.table.clickedFilter(this.currentFilter);
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.verifications.selected = null;
    },
  }
}
</script>
